<template>
	<div class="container-fluid">
        <div class="row">
            <div class="col-12">
				<div class="box">
		            <DateRange
		                :start.sync="start_date"
		                :end.sync="end_date"
		                @submit="loadAnalyse"
		            />

		            <CustomTable
		                id_table="demande_analyse"
		                :items="analyses"
		                :busy.sync="table_busy"
		                primaryKey="analyse_id"
		                :hrefsRoutes="config_table_hrefs"
						:externSlotColumns="extern_slot_columns"
		                :hide_if_empty="true"
		            >
    					<template v-slot:custom-slot-cell(analyse_pdf_url)="{ data }">
    						<a :href="data.analyse_pdf_url" target="_blank">
    							<font-awesome-icon class="previewColIdentifier" :icon="['fas', 'eye']" />
    						</a>
    					</template>
    					<template v-slot:custom-slot-cell(analyse_actetype)="{ data }">
    						<span v-for="(acte_type, idx) in data.analyse_actetype" :key="data.analyse_id+'_'+idx">
    							{{ acte_type }}<br>
    						</span>
    					</template>
			        </CustomTable>
					</div>
				</div>
		</div>
        <ModalEditAnalyse
            ref="modal_edit_analyse"
        />
	</div>
</template>

<script type="text/javascript">
	import ResultatAnalyse from "@/mixins/ResultatAnalyse.js"
	import Navigation from "@/mixins/Navigation.js"

	export default {
		name: 'DemandeAnalyse',
		mixins: [ResultatAnalyse, Navigation],
		data () {
			return {
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
				end_date: new Date(new Date().setMonth(new Date().getMonth() + 3)),
				analyses: [],
				table_busy: false,
				config_table_hrefs: {
					'analyse_demandeur.tiers_rs': {
						routeUniqueName: 'demandeurFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'analyse_demandeur.tiers_id'
						}
					},
					'analyse_invoicer.tiers_rs': {
						routeUniqueName: 'invoicerFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'analyse_invoicer.tiers_id'
						}
					},
					'analyse_horse.horse_nom': {
						routeUniqueName: 'jumentFiche',
	                    routeName: 'HorseFicheInfo',
	                    params: {
	                        horse_id: 'analyse_horse.horse_id'
	                    }
	                }
				},
	            extern_slot_columns: ['analyse_pdf_url', 'analyse_actetype'],
				events_tab: {
	                'TableAction::goToAddEditDemandeAnalyse': this.openEditModal,
	            }
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadAnalyse()
			},

			async loadAnalyse() {
				this.table_busy = true
				this.analyses = await this.getDemandeAnalyseByDate(this.start_date.toDateInputValue(), this.end_date.toDateInputValue());
				this.table_busy = false
			},

			openEditModal(analyse) {
				this.$refs.modal_edit_analyse.openModal(analyse)
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
	        DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
			ModalEditAnalyse: () => import('@/components/Modals/ModalEditAnalyse'),
		}
	}

</script>